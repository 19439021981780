// imports

import { TagTypes } from 'src/components/PropertyListCard';
import {
  propertyPillsPortal,
  propertyPillsWebsite
} from '../Constants/PropertyPillsData';

// Sorted Pill

export const SortedPills = (
  {
    isRecommended = false,
    isReduced = false,
    isSneakPeek = false,
    isNewHome = false,
    isBuildToRent = false,
    isRecentSold = false,
    isRecentLet = false,
    availableForAuction = false,
    landLordAcceptZeroDeposit = false,
    isNew = false,
    isLastViewed = false,
    isTopTenancy = false,
    isQuickSale = false,
    // portal pills
    isChecked = false,
    isMatched = false,
    hopefullyOfInterest = false,
    isProperty = false,
    handpickedForYou = false
  }: TagTypes,
  total: number = null,
  isPortal: boolean
) => {
  const totalActivePill = [];

  if (isRecommended) totalActivePill.push('Recommended');
  if (isReduced) totalActivePill.push('Reduced');
  if (isSneakPeek) totalActivePill.push('Sneak peek');
  if (isNewHome) totalActivePill.push('New homes');
  if (isBuildToRent) totalActivePill.push('Build to rent');
  if (isRecentSold) totalActivePill.push('Recently sold');
  if (isRecentLet) totalActivePill.push('Recently let');
  if (availableForAuction) totalActivePill.push('Auction');
  if (landLordAcceptZeroDeposit) totalActivePill.push('Zero deposit');
  if (isNew) totalActivePill.push('New');
  if (isLastViewed) totalActivePill.push('Last viewed');
  if (isTopTenancy) totalActivePill.push('Top tenancy');
  if (isQuickSale) totalActivePill.push('Quick Sale');
  // portal tags
  if (isChecked) totalActivePill.push('Checked');
  if (isMatched) totalActivePill.push('Matched');
  if (hopefullyOfInterest) totalActivePill.push('Hopefully of interest');
  if (handpickedForYou) totalActivePill.push('Handpicked for you');
  if (isProperty) totalActivePill.push('Property');

  let filteredPillsArr = [];

  if (isPortal) {
    filteredPillsArr = propertyPillsPortal.filter((pill) =>
      totalActivePill.some((lb) => pill.label === lb)
    );
  } else {
    filteredPillsArr = propertyPillsWebsite.filter((pill) =>
      totalActivePill.some((lb) => pill.label === lb)
    );
  }

  let sortedPills = filteredPillsArr.sort((a, b) => a.priority - b.priority);

  // conditions

  if (total) {
    sortedPills = sortedPills.slice(0, total);
  }

  return sortedPills;
};
