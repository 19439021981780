import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgBathtub = (props) => (
  <SvgIcon {...props} title="Bathtub">
    <svg
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4.883c0-.318-.124-.617-.345-.84a1.116 1.116 0 0 0-.795-.338h-7.6V2.012c0-.296.098-.568.274-.77a.817.817 0 0 1 .618-.298c.367 0 .649.181.791.47a.464.464 0 0 0-.4.47c0 .26.2.471.448.471h1.006a.46.46 0 0 0 .449-.472.46.46 0 0 0-.449-.472H4.89A1.84 1.84 0 0 0 4.413.52 1.752 1.752 0 0 0 3.153 0c-.487 0-.942.216-1.28.604-.33.379-.51.88-.51 1.408v1.693H1.14c-.297 0-.58.12-.795.338a1.186 1.186 0 0 0-.345.84 1.186 1.186 0 0 0 .692 1.08v1.854c0 .981.55 1.83 1.341 2.223v.488c0 .26.201.472.449.472a.46.46 0 0 0 .448-.472v-.257c.032.002.064.002.097.002h4.946c.033 0 .065 0 .097-.002v.257c0 .26.2.472.448.472a.46.46 0 0 0 .449-.472v-.488c.792-.393 1.341-1.242 1.341-2.223V5.963A1.186 1.186 0 0 0 11 4.882Zm-.134 0a1.041 1.041 0 0 1-.692.983v1.95c0 .957-.554 1.78-1.341 2.133v.58a.323.323 0 0 0 0 0v-.58c.787-.354 1.341-1.176 1.341-2.132V5.866a1.041 1.041 0 0 0 .692-.983Zm-8.74-1.037V2.012c0-.329.11-.636.31-.866a.948.948 0 0 0 0 0c-.2.23-.31.537-.31.865v1.835Zm1.623-1.75a.308.308 0 0 0 .242.118h1.006a.323.323 0 0 1 0 0H3.99a.308.308 0 0 1-.242-.119Zm1.032-.544ZM.358 5.531a1.011 1.011 0 0 0 .467.335 1.011 1.011 0 0 1-.467-.335Zm.977 3.765a2.225 2.225 0 0 1 0 0Zm.905 1.444a.308.308 0 0 0 .242.119.323.323 0 0 0 .314-.33v-.41a2.12 2.12 0 0 0 .23.013h4.947a2.203 2.203 0 0 0 .23-.012 2.117 2.117 0 0 1-.23.012H3.026c-.077 0-.154-.004-.23-.012v.408c0 .182-.14.33-.314.33a.308.308 0 0 1-.242-.118Zm7.171-4.68v1.757c0 .834-.645 1.513-1.438 1.513H3.027c-.793 0-1.438-.68-1.438-1.513V6.06H9.41Zm.449-.944H1.14a.249.249 0 0 1-.243-.233c0-.115.109-.234.243-.234h8.72c.134 0 .243.12.243.234a.249.249 0 0 1-.243.233ZM1.817 8.872a1.7 1.7 0 0 1-.363-1.055V5.919h8.091-8.09v1.898c0 .4.136.768.362 1.055ZM.844 5.109a.367.367 0 0 1-.081-.227c0-.2.176-.375.377-.375h8.72-8.72a.386.386 0 0 0-.377.376c0 .083.03.162.081.226Z"
        fill="#3A3A3A"
      />
    </svg>
  </SvgIcon>
);

export default SvgBathtub;
