// libs
import React from 'react';
import { Box, Chip } from '@mui/material';
// styles&utils
import { SortedPills } from '../../utils/customHelperFunctions/PillsFunction';
import styles from './styles';
import { TagTypes } from '../PropertyListCard';

export interface LibPropertyPillProps {
  totalNoTags?: number;
  tagsList: TagTypes;
  isPropertyDetail?: boolean;
  isPortal: boolean;
}

function LibPropertyPill({
  totalNoTags,
  tagsList,
  isPropertyDetail = false,
  isPortal = false
}: LibPropertyPillProps) {
  const sortedPills = SortedPills(tagsList, totalNoTags, isPortal);

  return (
    <Box
      sx={{
        ...styles.container,
        justifyContent: isPropertyDetail ? 'flex-start' : 'flex-end',
        position: isPropertyDetail ? 'unset' : 'absolute'
      }}
    >
      {sortedPills.map((pill) => (
        <Chip
          key={pill?.label}
          label={pill?.label}
          sx={(theme) => ({
            ...theme.typography.body2,
            backgroundColor: pill?.bgColor,
            color: pill?.textColor,
            ...styles.pillStyle
          })}
        />
      ))}
    </Box>
  );
}

export const PropertyPill = React.memo(LibPropertyPill);

export default PropertyPill;
