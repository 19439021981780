import { lightTheme } from '../../theme/index';

const { palette } = lightTheme;

export const propertyPillsWebsite = [
  {
    label: 'Recommended',
    bgColor: palette.brightBlue['100'],
    textColor: palette.grey['50'],
    priority: 0
  },
  {
    label: 'Last viewed',
    bgColor: palette.vineGreen[100],
    textColor: palette.grey[50],
    priority: 1
    // similar to recommended (+ recommended and last-viewed can not come together)
    // First tag on homepage on recommended property array
  },
  {
    label: 'Top tenancy',
    bgColor: '#9D2CD2',
    textColor: palette.grey[50],
    priority: 1
  },
  {
    label: 'Quick Sale',
    bgColor: palette.electricIndigo.main,
    textColor: palette.grey[50],
    priority: 2
  },
  {
    label: 'Recently let',
    bgColor: palette.bloodRed['100'],
    textColor: palette.grey['50'],
    priority: 2
  },
  {
    label: 'Recently sold',
    bgColor: palette.bloodRed['100'],
    textColor: palette.grey['50'],
    priority: 3
  },
  {
    label: 'Sneak peek',
    bgColor: palette.lightishAqua['200'],
    textColor: palette.grey['50'],
    priority: 4
  },
  {
    label: 'Auction',
    bgColor: palette.catalinaBlue.main,
    textColor: palette.grey['50'],
    priority: 5
  },
  {
    label: 'New homes',
    bgColor: palette.vineGreen['200'],
    textColor: palette.grey['50'],
    priority: 6
  },
  {
    label: 'Build to rent',
    bgColor: palette.vineGreen['200'],
    textColor: palette.grey['50'],
    priority: 7
  },
  {
    label: 'Zero deposit',
    bgColor: palette.secondary.main,
    textColor: palette.grey.main,
    priority: 8
  },
  {
    label: 'Open house',
    bgColor: palette.lightishAqua['300'],
    textColor: palette.grey['50'],
    priority: 9
  },
  {
    label: 'Reduced',
    bgColor: palette.brightBlue.main,
    textColor: palette.grey['50'],
    priority: 10
  },
  // Priority abt these tags?
  {
    label: 'New',
    bgColor: palette.secondary.main,
    textColor: palette.grey.main,
    priority: 11
    // ?: Need to ask eman
  },
  {
    label: 'Premium',
    bgColor: palette.torchRed['100'],
    textColor: palette.grey.main,
    priority: 12
    // ?: Need to ask eman
  },
  // unconfirmed pills of portal --------------------
  {
    label: 'Checked',
    bgColor: palette.torchRed['100'],
    textColor: palette.grey.main,
    priority: 13
    // ?: Need to ask eman
  },
  {
    label: 'Matched',
    bgColor: palette.torchRed['100'],
    textColor: palette.grey.main,
    priority: 14
    // ?: Need to ask eman
  },
  {
    label: 'property',
    bgColor: palette.torchRed['100'],
    textColor: palette.grey.main,
    priority: 15
    // ?: Need to ask eman
  },
  {
    label: 'Hopefully of interest',
    bgColor: palette.torchRed['100'],
    textColor: palette.grey.main,
    priority: 16
    // ?: Need to ask eman
  }
];
export const propertyPillsPortal = [
  {
    label: 'Recommended',
    bgColor: palette.brightBlue['100'],
    textColor: palette.grey['50'],
    priority: 4
  },
  {
    label: 'Last viewed',
    bgColor: palette.vineGreen[100],
    textColor: palette.grey[50],
    priority: 5
    // similar to recommended (+ recommended and last-viewed can not come together)
    // First tag on homepage on recommended property array
  },
  {
    label: 'Recently let',
    bgColor: palette.bloodRed['100'],
    textColor: palette.grey['50'],
    priority: 6
  },
  {
    label: 'Recently sold',
    bgColor: palette.bloodRed['100'],
    textColor: palette.grey['50'],
    priority: 7
  },
  {
    label: 'Sneak peek',
    bgColor: palette.lightishAqua['200'],
    textColor: palette.grey['50'],
    priority: 0
  },
  {
    label: 'Auction',
    bgColor: palette.catalinaBlue.main,
    textColor: palette.grey['50'],
    priority: 8
  },
  {
    label: 'New homes',
    bgColor: palette.vineGreen['200'],
    textColor: palette.grey['50'],
    priority: 9
  },
  {
    label: 'Build to rent',
    bgColor: palette.vineGreen['200'],
    textColor: palette.grey['50'],
    priority: 10
  },
  {
    label: 'Zero deposit',
    bgColor: palette.secondary.main,
    textColor: palette.grey.main,
    priority: 1
  },
  {
    label: 'Open house',
    bgColor: palette.lightishAqua['300'],
    textColor: palette.grey['50'],
    priority: 11
  },
  {
    label: 'Reduced',
    bgColor: palette.brightBlue.main,
    textColor: palette.grey['50'],
    priority: 12
  },
  // Priority abt these tags?
  {
    label: 'New',
    bgColor: palette.secondary.main,
    textColor: palette.grey.main,
    priority: 13
    // ?: Need to ask eman
  },
  {
    label: 'Premium',
    bgColor: palette.torchRed['100'],
    textColor: palette.grey.main,
    priority: 14
    // ?: Need to ask eman
  },
  {
    label: 'Checked',
    bgColor: palette.torchRed['100'],
    textColor: palette.grey.main,
    priority: 15
  },
  {
    label: 'Matched',
    bgColor: palette.torchRed['100'],
    textColor: palette.grey.main,
    priority: 16
  },
  {
    label: 'property',
    bgColor: palette.torchRed['100'],
    textColor: palette.grey.main,
    priority: 17
  },
  {
    label: 'Hopefully of interest',
    bgColor: palette.brightBlue['100'],
    textColor: palette.grey['50'],
    priority: 2
  },
  {
    label: 'Handpicked for you',
    bgColor: palette.brightBlue['100'],
    textColor: palette.grey['50'],
    priority: 3
  }
];

// Heicheary of bottom listed pills?
// Event Let
// Short Let (del?)
// Long Let
// New
// Last Viewed (if last viewed, should appear before anyone else but after recommended)
//

// NEED Deletion: for-sale, to-let, short-let
// We should not display “short let”, “long let”, “for sale” tags

// Tags missing in API: open-house(API: openhouse[]), ?recommended(FE)?, new(FE:prop-not-stored-in-LocalStg), ?last-viewed(FE)?, premium(API: isPrime)

/*

Case 01: If property reference exists in local-storage, show tag 'Last-viewed'. This tag will have highest priority but after recommended tag.
         For property-detail page, as recommended tag must not shown there, this tag will take highest priority. 

Case 02: Recommended property tag will not come in API but will be managed from FE. It will be passed boolean-true as a prop on properties shown
         in recommended-properties-fold on home-page and property-list page

Case 03: If instruction-type is event, we would show tag of 'Event-Let'. Its priority would be (?)
Case 04: If openhouse array is not empty (will be incorporated in API later), it would show 'open-house' tag. 
Case 05: If isPremium is true in API response, show that tag (priority = ?)



*/
