import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';
import { isReactApp } from '../../utils/reactUtils';

const { palette, spacing } = lightTheme;
const isMyFoxtons = isReactApp();

const styles: Record<string, SxProps> = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },

  title: {
    color: palette.primary.main,
    ...(isMyFoxtons && {
      fontSize: '1.25rem',
      lineHeight: 1.5,
      fontWeight: 400
    })
  },

  subtitle: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
    color: palette.grey.main,
    mt: '1.25rem'
  },

  text: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: palette.grey.main,
    mt: spacing(0.5)
  },

  endingText: {
    fontWeight: 500
  },

  divider: {
    width: '100%',
    borderBottom: `1px solid ${palette.primary['300']}`,
    mt: spacing(0.5),
    mb: spacing(1)
  },
  subDivider: {
    width: '100%',
    borderBottom: `1px solid ${palette.primary['300']}`,
    mt: spacing(0.5)
  },
  subSectionSubTitle: {
    fontWeight: '600',
    color: palette.grey.main,
    mt: spacing(0.5)
  },

  closeBtn: {
    width: '100%',
    mt: 'auto'
  }
};

export default styles;
