import React from 'react';
import { SvgIcon } from '@mui/material';

const SvgBed = (props) => (
  <SvgIcon {...props} title="Bed">
    <svg
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.25 4.29V2.668c0-.963-.788-1.75-1.75-1.75H7.165c-.45 0-.857.175-1.167.455A1.735 1.735 0 0 0 4.833.918H2.499c-.962 0-1.75.787-1.75 1.75V4.29c-.355.32-.583.781-.583 1.295V8.5c0 .321.263.584.583.584.321 0 .584-.263.584-.584v-.583h9.333v.583c0 .321.262.584.583.584.321 0 .584-.263.584-.584V5.585c0-.514-.228-.975-.584-1.295ZM7.165 2.085h2.333c.321 0 .584.262.584.583v1.167h-3.5V2.668c0-.32.262-.583.583-.583Zm-5.25.583c0-.32.263-.583.583-.583h2.334c.32 0 .583.262.583.583v1.167h-3.5V2.668Zm-.583 4.083V5.585c0-.321.262-.584.583-.584h8.167c.32 0 .583.263.583.584V6.75H1.333Z"
        fill="#3A3A3A"
      />
    </svg>
  </SvgIcon>
);

export default SvgBed;
