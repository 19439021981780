import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';

const { spacing } = lightTheme;

const styles: Record<string, SxProps> = {
  container: {
    position: 'absolute',
    top: spacing(1),
    right: spacing(1),
    zIndex: 3,
    width: '72%',
    display: 'flex',
    '& > div': {
      gap: '0.5rem',
      display: 'flex',
      alignItems: 'center'
    },
    gap: '0.5rem',
    flexWrap: 'wrap'
  },
  pillStyle: {
    height: '1.5rem'
  }
};

export default styles;
