import React, { useState, forwardRef } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

// styles
import deepmerge from '@mui/utils/deepmerge';
import styles from './styles';
import { dynamicImport } from '../../utils/reactUtils';

const Drawer = dynamicImport(() => import('@mui/material/Drawer'));
const Close = dynamicImport(() => import('@mui/icons-material/Close'));

interface LibGeneralDrawerWrapperProps {
  children?: React.ReactNode;
  title: string;
  isOpen?: boolean;
  handleClose?: () => void;
  containerStyle?: SxProps<{}>;
  headerStyle?: SxProps<{}>;
}

const LibGeneralDrawerWrapper = forwardRef(
  (
    {
      children,
      title = '',
      handleClose = undefined,
      isOpen = undefined,
      containerStyle = {},
      headerStyle = {}
    }: LibGeneralDrawerWrapperProps,
    ref
  ) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleDrawerClose = () => {
      setIsDrawerOpen(false);
    };

    const handleDrawerOpen = () => {
      setIsDrawerOpen(true);
    };

    React.useImperativeHandle(ref, () => ({
      handleDrawerOpen,
      handleDrawerClose
    }));

    return (
      <Drawer
        anchor="right"
        open={isOpen !== undefined ? isOpen : isDrawerOpen}
        onClose={handleClose !== undefined ? handleClose : handleDrawerClose}
        sx={deepmerge(styles.drawerContainer, containerStyle)}
      >
        <Box sx={deepmerge(styles.headerStyles, headerStyle)}>
          <Typography variant="h2" sx={styles.title}>
            {title}
          </Typography>
          <Close
            onClick={
              handleClose !== undefined ? handleClose : handleDrawerClose
            }
          />
        </Box>
        <Box sx={styles.children}>{children}</Box>
      </Drawer>
    );
  }
);

LibGeneralDrawerWrapper.displayName = 'GeneralDrawerWrapper';

export const GeneralDrawerWrapper = React.memo(LibGeneralDrawerWrapper);

export default GeneralDrawerWrapper;
