import { SxProps } from '@mui/material';
import { lightTheme } from '../../theme/index';
import { isReactApp } from '../../utils/reactUtils';

const { spacing } = lightTheme;
const isMyFoxtons = isReactApp();

const styles: Record<string, SxProps> = {
  drawerContainer: {
    '.MuiPaper-root': {
      width: { xs: '100%', md: '31.125rem' },
      padding: {
        xs: spacing(1),
        md: spacing(2)
      }
    }
  },

  headerStyles: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    gap: spacing(1),
    pb: spacing(2),

    span: {
      width: { xs: '17rem', md: '23rem' }
    },

    svg: {
      cursor: 'pointer'
    }
  },
  title: {
    ...(isMyFoxtons && {
      fontSize: '1.5rem',
      lineHeight: 1.1,
      fontWeight: 500
    })
  },

  children: {
    height: '100%'
  }
};

export default styles;
