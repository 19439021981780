import React, { forwardRef } from 'react';
import { Box, Typography } from '@mui/material';
import deepmerge from '@mui/utils/deepmerge';
// src
import { Button } from '../../components/Button/index';
import GeneralDrawerWrapper from '../GeneralDrawerWrapper';
// styles
import { feesCalc } from '../../utils/index';
import styles from './styles';
import { LONG_LET_DEPOSIT_TEXT, SHORT_LET_DEPOSIT_TEXT } from './constants';

export interface LibFeesMayApplySectionProps {
  propertyType: string;
  propertyName: string;
  propertyPrice: string | number;
}

const LibFeesMayApplySection = forwardRef(
  (
    { propertyType, propertyName, propertyPrice }: LibFeesMayApplySectionProps,
    ref
  ) => {
    // Handle-CloseDrawer: Closing drawer via close btn
    const handleDrawerClose = () => {
      ref?.current?.handleDrawerClose();
    };

    return (
      <GeneralDrawerWrapper ref={ref} title={propertyName}>
        <Box sx={styles.container} id="fees-may-apply-box">
          <Typography variant="h3" sx={styles.title}>
            Deposit
          </Typography>
          <Typography variant="subtitle2" sx={styles.subSectionSubTitle}>
            {propertyType === 'letting'
              ? LONG_LET_DEPOSIT_TEXT
              : SHORT_LET_DEPOSIT_TEXT}
          </Typography>
          <Typography sx={styles.text}>{`£${feesCalc(
            propertyType,
            propertyPrice
          )}`}</Typography>
          <Box sx={styles.divider} />

          <Typography variant="h3" sx={styles.title}>
            Other fees
          </Typography>
          <Typography variant="subtitle2" sx={styles.subSectionSubTitle}>
            Contract variation, novation, amendment or change of occupant at the
            tenant’s request within an existing tenancy
          </Typography>
          <Typography sx={styles.text}>£50</Typography>
          <Box sx={styles.subDivider} />

          <Typography variant="subtitle2" sx={styles.subSectionSubTitle}>
            Default fee of interest on late rent if rent is more than 14 days
            overdue
          </Typography>
          <Typography sx={styles.text}>
            3% above Bank of England base rate
          </Typography>
          <Box sx={styles.subDivider} />

          <Typography variant="subtitle2" sx={styles.subSectionSubTitle}>
            Default fee for lost keys or other respective security devices
          </Typography>
          <Typography sx={styles.text}>Actual cost of replacement</Typography>
          <Box sx={styles.subDivider} />

          <Typography sx={deepmerge(styles.text, styles.endingText)}>
            All fees unless otherwise stated are inclusive of VAT
          </Typography>

          <Button
            variant="primary-dark"
            sx={styles.closeBtn}
            onClick={handleDrawerClose}
          >
            Close
          </Button>
        </Box>
      </GeneralDrawerWrapper>
    );
  }
);

LibFeesMayApplySection.displayName = 'FeesMayApplySection';

export const FeesMayApplySection = React.memo(LibFeesMayApplySection);

export default FeesMayApplySection;
